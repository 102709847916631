class SubCategoryCarousel extends HTMLElement {
  connectedCallback(): void {
    document.querySelectorAll('.subcategoryCarousel a').forEach(function (item) {
      if (window.location.href.includes(item.getAttribute("href"))) {
        item.classList.add("br_active")
      }
    })
  }
}

customElements.define("bellroy-sub-category-carousel", SubCategoryCarousel);
